import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "../ComplianceSearch/useStyles";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../api/compliance";
import usePagination from "../../hooks/usePagination";
import { appendCommaSeparatedValuesToQuery, TitleUpdated } from "../ComplianceSearch/utils";
import { ComplianceSearchActions } from "../../reducers/ComplianceSearchReducer/actions";
import { FilterAutocomplete } from "../../components/ui";
import { COLUMNS } from "./constants";
import Table from "../ComplianceSearch/components/Table";

const HeliosNotesSearch: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pagination = usePagination();

  const [search, setSearch] = useState({
    names: "",
    email: "",
  });

  const {
    loading,
    data: { results, updated },
  } = useSelector((state) => state.complianceSearch.watchlistNotes);

  useQuery({
    retry: false,
    queryKey: [
      COMPLIANCE_API_URLS.getSearchNotes,
      Object.values(search),
      pagination.page,
      pagination.limit,
    ],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      for (const [key, val] of Object.entries(search)) {
        if (val) {
          if (key === "names") {
            appendCommaSeparatedValuesToQuery(val, query, "names");
          } else {
            query.append(key, val);
          }
        }
      }

      dispatch(ComplianceSearchActions.fetchWatchlistNotesRequest());
      const response = await ComplianceAPI.getSearchNotes(query.toString(), signal);
      pagination.setCount(response.data.results.count);
      dispatch(ComplianceSearchActions.fetchWatchlistNotesSuccess(response.data));
      return response;
    },
    onError(error) {
      console.error("Error fetching helios notes search", error);
      dispatch(ComplianceSearchActions.fetchWatchlistNotesFailed());
    },
  });

  const handleChangePage = (page: number) => pagination.setPage(page);

  return (
    <div>
      <b className={classes.title}>Watchlist notes</b>

      <div>
        <FilterAutocomplete
          search={search}
          setSearch={setSearch}
          filters={{
            Name: "names",
            Email: "email",
          }}
        />
      </div>

      <Table
        columns={COLUMNS}
        title={<TitleUpdated value={updated?.measure_timestamp} />}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        extractKey={(row) => row.id}
        data={results.rows}
      />
    </div>
  );
};

export default HeliosNotesSearch;
