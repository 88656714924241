import React from "react";

import HeliosPassesSearch from "../HeliosPassesSearch";
import HeliosWithdrawalsSearch from "../HeliosWithdrawalsSearch";
import HeliosFailedSearch from "../HeliosFailedSearch";
import HeliosLiveSIMTraderInformationFormSearch from "../HeliosLiveSIMTraderInformationFormSearch";
import HeliosLiveTraderInformationFormSearch from "../HeliosLiveTraderInformationFormSearch";
import HeliosNotesSearch from "../../../HeliosNotesSearch";
import useStyles from "../../useStyles";
import AppiusLiveTIFSearch from "../AppiusLiveTIFSearch";

const HeliosQuickSearch: React.FC = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.tableContainer}>
        <HeliosPassesSearch />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <HeliosWithdrawalsSearch />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <HeliosFailedSearch />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <HeliosLiveSIMTraderInformationFormSearch />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <HeliosLiveTraderInformationFormSearch />
      </div>
      <hr />
      <div>
        <AppiusLiveTIFSearch />
      </div>
      <hr />
      <div className={classes.tableContainer}>
        <HeliosNotesSearch />
      </div>
    </div>
  );
};

export default HeliosQuickSearch;
