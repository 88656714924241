import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "../../useStyles";
import { FilterAutocomplete } from "../../../../components/ui";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { appendCommaSeparatedValuesToQuery } from "../../utils";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import usePagination from "../../../../hooks/usePagination";
import Table from "../Table";
import {
  HELIOS_LIVE_SIM_TIF_COLUMNS,
  HELIOS_LIVE_TIF_COLUMNS,
  PASSES_COLUMNS,
} from "../../constants";

const HeliosRelatedSearch: React.FC = () => {
  const classes = useStyles();
  const pagination = usePagination();
  const dispatch = useDispatch();

  const [search, setSearch] = useState({
    names: "",
    email: "",
    phone: "",
    address: "",
  });

  const {
    loading,
    data: { passes, liveSimTif, liveTif },
  } = useSelector((state) => state.complianceSearch.heliosAccountsInvestigation);

  const handleChangePage = (page: number) => pagination.setPage(page);

  useQuery({
    retry: false,
    queryKey: [
      COMPLIANCE_API_URLS.getHeliosRelatedSearch,
      Object.values(search),
      pagination.page,
      pagination.limit,
    ],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());

      for (const [key, val] of Object.entries(search)) {
        if (val) {
          if (key === "names") {
            appendCommaSeparatedValuesToQuery(val, query, "names");
          } else {
            query.append(key, val);
          }
        }
      }

      dispatch(ComplianceSearchActions.fetchHeliosAccountsInvestigationRequest());
      const response = await ComplianceAPI.getHeliosRelatedSearch(query.toString(), signal);
      pagination.setCount(response.data.passes.count);
      dispatch(ComplianceSearchActions.fetchHeliosAccountsInvestigationSuccess(response.data));
      return response;
    },
    onError(error) {
      console.error("Error fetching getHeliosRelatedSearch", error);
      dispatch(ComplianceSearchActions.fetchHeliosAccountsInvestigationFailed());
    },
  });

  useEffect(() => {
    pagination.setPage(0);
  }, [search, pagination.limit]);

  return (
    <div>
      <div>
        <FilterAutocomplete
          search={search}
          setSearch={setSearch}
          filters={{
            Name: "names",
            Email: "email",
            Phone: "phone",
            Address: "address",
          }}
        />
      </div>
      <div>
        <Table
          columns={PASSES_COLUMNS}
          data={passes.rows}
          loading={loading}
          extractKey={(row) => row.id}
          theadTitle="Passes"
          pagination={pagination}
          handleChangePage={handleChangePage}
        />
      </div>

      <div className={classes.heliosRelatedSearch}>
        <div>
          <Table
            columns={HELIOS_LIVE_SIM_TIF_COLUMNS}
            data={liveSimTif.rows}
            loading={loading}
            extractKey={(row) => row.id}
            theadTitle="Live Sim TIF"
          />
        </div>
        <div>
          <Table
            columns={HELIOS_LIVE_TIF_COLUMNS}
            data={liveTif.rows}
            loading={loading}
            extractKey={(row) => row.id}
            theadTitle="Live TIF"
          />
        </div>
      </div>
    </div>
  );
};

export default HeliosRelatedSearch;
