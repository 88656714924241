import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "../../useStyles";
import { FilterAutocomplete } from "../../../../components/ui";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import usePagination from "../../../../hooks/usePagination";
import { appendCommaSeparatedValuesToQuery, TitleUpdated } from "../../utils";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import Table from "../Table";
import { HELIOS_LIVE_TIF_COLUMNS } from "../../constants";

const HeliosLiveTraderInformationFormSearch: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pagination = usePagination();

  const {
    loading,
    data: { results, updated },
  } = useSelector((state) => state.complianceSearch.watchlistLiveTif);

  const [search, setSearch] = useState({
    names: "",
    phone: "",
    email: "",
    address: "",
  });

  useQuery({
    retry: false,
    queryKey: [
      COMPLIANCE_API_URLS.getHeliosLiveTif,
      Object.values(search),
      pagination.page,
      pagination.limit,
    ],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      for (const [key, val] of Object.entries(search)) {
        if (val) {
          if (key === "names") {
            appendCommaSeparatedValuesToQuery(val, query, "names");
          } else {
            query.append(key, val);
          }
        }
      }
      dispatch(ComplianceSearchActions.fetchWatchlistLiveTifRequest());
      const response = await ComplianceAPI.getWatchlistLiveTif(query.toString(), signal);
      dispatch(ComplianceSearchActions.fetchWatchlistLiveTifSuccess(response.data));
      pagination.setCount(response.data.results.count);
      return response;
    },
    onError(error) {
      console.error("Error fetching Live Trader Information Form", error);
      dispatch(ComplianceSearchActions.fetchWatchlistLiveTifFailed());
    },
  });

  const handleChangePage = (page: number) => pagination.setPage(page);

  return (
    <div>
      <b className={classes.title}>Helios Live TIF</b>

      <div>
        <FilterAutocomplete
          search={search}
          setSearch={setSearch}
          filters={{
            Name: "names",
            Email: "email",
            Phone: "phone",
            Address: "address",
          }}
        />
      </div>
      <Table
        columns={HELIOS_LIVE_TIF_COLUMNS}
        title={<TitleUpdated value={updated?.submission_timestamp} />}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        extractKey={(row) => row.id}
        data={results.rows}
      />
    </div>
  );
};

export default HeliosLiveTraderInformationFormSearch;
