import React from "react";
import HeliosRelatedSearch from "../HeliosRelatedSearch";

const HeliosAccountsInvestigation: React.FC = () => {
  return (
    <div>
      <HeliosRelatedSearch />
    </div>
  );
};

export default HeliosAccountsInvestigation;
