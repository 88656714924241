import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "../../useStyles";
import { FilterAutocomplete } from "../../../../components/ui";
import { HELIOS_LIVE_SIM_TIF_COLUMNS } from "../../constants";
import Table from "../Table";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import usePagination from "../../../../hooks/usePagination";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import { appendCommaSeparatedValuesToQuery, TitleUpdated } from "../../utils";

const HeliosLiveSIMTraderInformationFormSearch: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const pagination = usePagination();
  const {
    loading,
    data: { results, updated },
  } = useSelector((state) => state.complianceSearch.watchlistLiveSimTif);
  const [search, setSearch] = useState({
    names: "",
    email: "",
    status: "",
  });

  useQuery({
    retry: false,
    // enabled: !!(search.names || search.email || search.status),
    queryKey: [
      COMPLIANCE_API_URLS.getLiveSimTif,
      Object.values(search),
      pagination.page,
      pagination.limit,
    ],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());

      for (const [key, val] of Object.entries(search)) {
        if (val) {
          if (key === "names") {
            appendCommaSeparatedValuesToQuery(val, query, "names");
          } else {
            query.append(key, val);
          }
        }
      }

      dispatch(ComplianceSearchActions.fetchWatchlistLiveSimTifRequest());
      const response = await ComplianceAPI.getWatchlistLiveSimTif(query.toString(), signal);
      pagination.setCount(response.data.results.count);
      dispatch(ComplianceSearchActions.fetchWatchlistLiveSimTifSuccess(response.data));
      return response;
    },
    onError(error) {
      dispatch(ComplianceSearchActions.fetchWatchlistLiveSimTifFailed());
      console.error("Error fetching LiveSIM Trader Information Form", error);
    },
  });

  const handleChangePage = (page: number) => pagination.setPage(page);

  return (
    <div>
      <b className={classes.title}>LiveSIM TIF</b>
      <div>
        <FilterAutocomplete
          search={search}
          setSearch={setSearch}
          filters={{
            Name: "names",
            Email: "email",
            Status: "status",
          }}
        />
      </div>
      <Table
        title={<TitleUpdated value={updated?.submission_timestamp} />}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        columns={HELIOS_LIVE_SIM_TIF_COLUMNS}
        extractKey={(row) => row.id}
        data={results.rows}
      />
    </div>
  );
};

export default HeliosLiveSIMTraderInformationFormSearch;
