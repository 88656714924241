import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "../../useStyles";
import usePagination from "../../../../hooks/usePagination";
import SearchByListValues from "../SearchByListValues";
import { NAME_LIST_VALIDATION_SCHEMA } from "../../../../utils/validation";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import Table from "../Table";

import { appendCommaSeparatedValuesToQuery, TitleUpdated } from "../../utils";
import { PASSES_COLUMNS } from "../../constants";

const HeliosPassesSearch: React.FC = () => {
  const classes = useStyles();
  const pagination = usePagination();

  const dispatch = useDispatch();
  const {
    data: { results, updated },
    loading,
  } = useSelector((state) => state.complianceSearch.watchlistSubmissionToProp);

  const [search, setSearch] = useState("");

  const handleChangePage = (page: number) => pagination.setPage(page);

  useQuery({
    enabled: !!search,
    queryKey: [COMPLIANCE_API_URLS.getPasses, search, pagination.page, pagination.limit],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      appendCommaSeparatedValuesToQuery(search, query, "names");

      dispatch(ComplianceSearchActions.fetchWatchlistPassesRequest());
      const response = await ComplianceAPI.getWatchlistPassesRequest(query.toString(), signal);
      pagination.setCount(response.data.results.count);
      dispatch(ComplianceSearchActions.fetchWatchlistPassesSuccess(response.data));
      return response;
    },
    onError(error) {
      console.error("Error fetching passes", error);
      dispatch(ComplianceSearchActions.fetchWatchlistPassesFailed());
    },
  });

  return (
    <div>
      <b className={classes.title}>Passes</b>
      <SearchByListValues
        fetchData={(values) => {
          setSearch(values.names);
        }}
        isLoading={false}
        name="names"
        label="Name"
        placeholder="Name Surname, Name Surname, e.t.c ..."
        initialValues={{ names: "" }}
        validationSchema={NAME_LIST_VALIDATION_SCHEMA}
      />

      <Table
        columns={PASSES_COLUMNS}
        data={results.rows}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        extractKey={(row) => row.id}
        title={<TitleUpdated value={updated?.timestamp} />}
      />
    </div>
  );
};

export default HeliosPassesSearch;
