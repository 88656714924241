import React from "react";
import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import { CopyToClipboard } from "../../../../components/ui";

export const COLUMNS: Array<IColum> = [
  {
    by: "account_name",
    title: "Funded account name",
    path: "account_name",
  },
  {
    by: "name",
    title: "Name",
    path: "name",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "email",
    title: "Email",
    path: "email",
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "amount_requested",
    title: "Amount requested",
    path: "amount_requested",
  },
  {
    by: "w80",
    title: "80%",
    path: "w80",
  },
  {
    by: "w20",
    title: "20%",
    path: "w20",
  },
  {
    by: "request_date",
    title: "Request date",
    path: "request_date",
  },
  {
    by: "payout_date",
    title: "Payout date",
    path: "payout_date",
  },
  {
    by: "status",
    title: "Status",
    path: "status",
  },
];
