import React from "react";

import { IColum } from "../../../../utils/copyToClipboardInXlsFormat";
import { RenderCellValue, replaceCommaAndNewLineSymbols, replaceNewLineAndTrim } from "../../utils";
import { CopyToClipboard } from "../../../../components/ui";

export const COLUMNS: Array<IColum> = [
  {
    by: "timestamp",
    title: "Submission timestamp",
    path: "timestamp",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
  },
  {
    by: "name",
    title: "Name",
    path: "name",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "email",
    title: "Email",
    path: "email",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
    getCellValue: (value) => <CopyToClipboard value={value as unknown as string} />,
  },
  {
    by: "status",
    title: "Status",
    path: "status",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
  },
  {
    by: "dob",
    title: "DOB",
    path: "dob",
    copyToClipBoardCallBack: (value) => replaceNewLineAndTrim(value),
  },
  {
    by: "phone",
    title: "Phone",
    path: "phone",
    copyToClipBoardCallBack: (value: string): string => {
      if (value) {
        return value.replaceAll("+", "").replaceAll("\n", " ");
      }
      return "";
    },
  },
  {
    by: "address",
    title: "Address",
    path: "address",
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
  {
    by: "account_type",
    title: "Account type",
    path: "account_type",
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
  {
    by: "id_and_poa",
    title: "ID and POA",
    path: "id_and_poa",
    getCellValue: (value) => <RenderCellValue value={value as unknown as string} />,
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
  {
    by: "tax_form",
    title: "Tax form",
    path: "tax_form",
    getCellValue: (value) => <RenderCellValue value={value as unknown as string} />,
    copyToClipBoardCallBack: (value) => replaceCommaAndNewLineSymbols(value),
  },
];
