import {
  IGetAllEntriesResponse,
  IGetCustomersLoginsResponse,
  IGetPaymentCardsResponse,
  IUniqueReqIpResponse,
  IGetAccountPlansDataResponse,
  IGetAccountPlansStatusResponse,
  TGetAccountsPurchasesResponse,
  IGetAccountsResetsRebillsNewPurchasesResponse,
  IBasicAccountDetailsAndSalesValueResponse,
  IGetAllEntriesRequestByValuesResponse,
  IGetEvaluationsRithmicAccountsResponse,
  IGetPassesResponse,
  IWithdrawalsResponse,
  IFailedResponse,
  IGetWatchlistLiveSimTifResponse,
  IGetWatchlistLiveTifResponse,
  IGetWatchlistAppiusLiveTifResponse,
  IGetSearchNotesResponse,
  IGetHeliosRelatedSearchResponse,
} from "../../api/compliance/index.types";
import { ComplianceSearchAccountsAction, ComplianceSearchAccountsActionType } from "./index.types";

export const ComplianceSearchActions = {
  //SearchAccounts
  fetchComplianceSearchRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsRequest,
  }),
  fetchComplianceSearchSuccess: (
    payload: IGetAllEntriesResponse["data"]["accounts_table"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsSuccess,
    payload,
  }),
  fetchComplianceSearchFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchAccountsFailed,
  }),
  //Search logins
  fetchComplianceSearchLoginsRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsRequest,
  }),
  fetchComplianceSearchLoginsSuccess: (
    payload: IGetCustomersLoginsResponse["data"]["logins"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsSuccess,
    payload,
  }),
  fetchComplianceSearchLoginsFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchLoginsFailed,
  }),
  // Search unique req ip
  fetchComplianceSearchUniqueReqIpRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpRequest,
  }),
  fetchComplianceSearchUniqueReqIpSuccess: (
    payload: IUniqueReqIpResponse["data"]["usageData"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpSuccess,
    payload,
  }),
  fetchComplianceSearchUniqueReqIpFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchUniqueReqIpFailed,
  }),
  // SearchPaymentCards
  fetchComplianceSearchPaymentCardsRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsRequest,
  }),
  fetchComplianceSearchPaymentCardsSuccess: (
    payload: IGetPaymentCardsResponse["data"]["paymentCards"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsSuccess,
    payload,
  }),
  fetchComplianceSearchPaymentCardsFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPaymentCardsFailed,
  }),
  // SearchPlans
  fetchComplianceSearchPlansRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansRequest,
  }),
  fetchComplianceSearchPlansSuccess: (
    payload: IGetAccountPlansDataResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansSuccess,
    payload,
  }),
  fetchComplianceSearchPlansFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansFailed,
  }),
  // SearchPlansStatus
  fetchComplianceSearchPlansStatusRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusRequest,
  }),
  fetchComplianceSearchPlansStatusSuccess: (
    payload: IGetAccountPlansStatusResponse["data"]["accounts"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusSuccess,
    payload,
  }),
  fetchComplianceSearchPlansStatusFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPlansStatusFailed,
  }),
  // SearchPurchases
  fetchComplianceSearchPurchasesRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesRequest,
  }),
  fetchComplianceSearchPurchasesSuccess: (
    payload: TGetAccountsPurchasesResponse["data"]["purchasesHistory"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesSuccess,
    payload,
  }),
  fetchComplianceSearchPurchasesFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceSearchPurchasesFailed,
  }),
  // SearchResetsRebillsNewPurchases
  fetchComplianceSearchResetsRebillsNewPurchasesRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesRequest,
  }),
  fetchComplianceSearchResetsRebillsNewPurchasesSuccess: (
    payload: IGetAccountsResetsRebillsNewPurchasesResponse["data"]["accounts"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesSuccess,
    payload,
  }),
  fetchComplianceSearchResetsRebillsNewPurchasesFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchResetsRebillsNewPurchasesFailed,
  }),
  // BasicAccountDetailsAndSalesValue
  fetchComplianceSearchBasicAccountDetailsAndSalesValueRequest:
    (): ComplianceSearchAccountsAction => ({
      type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueRequest,
    }),
  fetchComplianceSearchBasicAccountDetailsAndSalesValueSuccess: (
    payload: IBasicAccountDetailsAndSalesValueResponse["data"]["accounts"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueSuccess,
    payload,
  }),
  fetchComplianceSearchBasicAccountDetailsAndSalesValueFailed:
    (): ComplianceSearchAccountsAction => ({
      type: ComplianceSearchAccountsActionType.fetchBasicAccountDetailsAndSalesValueFailed,
    }),
  // AccountSearchOnNameVariations
  fetchComplianceSearchByNameRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsRequest,
  }),
  fetchComplianceSearchByNameSuccess: (
    payload: IGetAllEntriesRequestByValuesResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsSuccess,
    payload,
  }),
  fetchComplianceSearchByNameFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchSearchAccountsOnNameVariationsFailed,
  }),
  // AccountSearchByPhone
  fetchComplianceSearchByPhoneRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneRequest,
  }),
  fetchComplianceSearchByPhoneSuccess: (
    payload: IGetAllEntriesRequestByValuesResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneSuccess,
    payload,
  }),
  fetchComplianceSearchByPhoneFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchComplianceAccountsByPhoneFailed,
  }),
  // SearchRithmicAccounts
  fetchComplianceSearchRithmicAccountsRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchRithmicAccountsRequest,
  }),
  fetchComplianceSearchRithmicAccountsSuccess: (
    payload: IGetEvaluationsRithmicAccountsResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchRithmicAccountsSuccess,
    payload,
  }),
  fetchComplianceSearchRithmicAccountsFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchRithmicAccountsFailed,
  }),
  // WatchlistPasses
  fetchWatchlistPassesRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropRequest,
  }),
  fetchWatchlistPassesSuccess: (
    payload: IGetPassesResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropSuccess,
    payload,
  }),
  fetchWatchlistPassesFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistSubmissionToPropFailed,
  }),
  // WatchlistWithdrawals
  fetchWatchlistWithdrawalsRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsRequest,
  }),
  fetchWatchlistWithdrawalsSuccess: (
    payload: IWithdrawalsResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsSuccess,
    payload,
  }),
  fetchWatchlistWithdrawalsFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistWithdrawalsFailed,
  }),
  // WatchlistFailed
  fetchWatchlistFailedRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistFailedRequest,
  }),
  fetchWatchlistFailedSuccess: (
    payload: IFailedResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistFailedSuccess,
    payload,
  }),
  fetchWatchlistFailedFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistFailedFailed,
  }),
  // WatchlistLiveSimTif
  fetchWatchlistLiveSimTifRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifRequest,
  }),
  fetchWatchlistLiveSimTifSuccess: (
    payload: IGetWatchlistLiveSimTifResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifSuccess,
    payload,
  }),
  fetchWatchlistLiveSimTifFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveSimTifFailed,
  }),
  // WatchlistLiveTif
  fetchWatchlistLiveTifRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveTifRequest,
  }),
  fetchWatchlistLiveTifSuccess: (
    payload: IGetWatchlistLiveTifResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveTifSuccess,
    payload,
  }),
  fetchWatchlistLiveTifFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistLiveTifFailed,
  }),
  // WatchlistAppiusLiveTif
  fetchWatchlistAppiusLiveTifRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifRequest,
  }),
  fetchWatchlistAppiusLiveTifSuccess: (
    payload: IGetWatchlistAppiusLiveTifResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifSuccess,
    payload,
  }),
  fetchWatchlistAppiusLiveTifFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistAppiusLiveTifFailed,
  }),
  // WatchlistNotes
  fetchWatchlistNotesRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistNotesRequest,
  }),
  fetchWatchlistNotesSuccess: (
    payload: IGetSearchNotesResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistNotesSuccess,
    payload,
  }),
  fetchWatchlistNotesFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchWatchlistNotesFailed,
  }),
  // HeliosAccountsInvestigation
  fetchHeliosAccountsInvestigationRequest: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationRequest,
  }),
  fetchHeliosAccountsInvestigationSuccess: (
    payload: IGetHeliosRelatedSearchResponse["data"]
  ): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationSuccess,
    payload,
  }),
  fetchHeliosAccountsInvestigationFailed: (): ComplianceSearchAccountsAction => ({
    type: ComplianceSearchAccountsActionType.fetchHeliosAccountsInvestigationFailed,
  }),
};
