import React, { useState } from "react";
import cx from "classnames";
import { Tab, Tabs, Paper } from "@material-ui/core";

import TabPanel from "../../components/ui/TabPanel";
import AccountsTable from "./components/AccountsTable";
import LogLoginsTable from "./components/LogLoginsTable";
import UniqueRegistrationIpTable from "./components/UniqueRegistrationIpTable";
import QuickSearch from "./components/QuickSearch";
import PaymentCards from "./components/PaymentCards";
import SearchAccountsPlans from "./components/SearchAccountsPlans";
import AllAccountSearch from "./components/AllAccountSearch";
import HeliosQuickSearch from "./components/HeliosQuickSearch";

import useStyles from "./useStyles";
import useGeneralStyles from "../../useGeneralStyles";
import { useAdminProviderDataContext } from "../../context";
import HeliosAccountsInvestigation from "./components/HeliosAccountsInvestigation";

const ComplianceSearch = (): React.ReactElement => {
  const classes = useStyles();
  const generalClasses = useGeneralStyles();

  const {
    user: { allowed_features },
  } = useAdminProviderDataContext();

  const [activeTabIndex, setActiveTab] = useState(0);
  const onChangeActiveTab = (_: React.BaseSyntheticEvent, newValue: number) =>
    setActiveTab(newValue);
  return (
    <div>
      <div>
        <Paper square>
          <Tabs variant="scrollable" value={activeTabIndex} onChange={onChangeActiveTab}>
            <Tab label="E2T Accounts Investigation" value={0} />
            <Tab label="E2T All accounts search" value={1} />
            <Tab
              label="E2T quick search"
              value={2}
              disabled={!allowed_features.access_to_compliance_quick_search}
            />
            <Tab
              label="E2T plans data"
              value={3}
              disabled={!allowed_features.access_to_compliance_search_plans}
            />
            <Tab label="Helios quick search" value={4} />
            <Tab label="Helios Accounts Investigation" value={5} />
          </Tabs>
        </Paper>
      </div>

      <div className={cx(generalClasses.containerBg, classes.mainContainer)}>
        <TabPanel value={activeTabIndex} index={0}>
          <div>
            <AccountsTable />
          </div>
          <hr className={classes.hr} />
          <div className={classes.relatedTablesContainer}>
            <div>
              <LogLoginsTable />
            </div>
            <div>
              <UniqueRegistrationIpTable />
            </div>
            <div>
              <PaymentCards />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={activeTabIndex} index={1}>
          <AllAccountSearch />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={2}>
          <QuickSearch />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={3}>
          <SearchAccountsPlans />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={4}>
          <HeliosQuickSearch />
        </TabPanel>
        <TabPanel value={activeTabIndex} index={5}>
          <HeliosAccountsInvestigation />
        </TabPanel>
      </div>
    </div>
  );
};

export default ComplianceSearch;
