import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "../../useStyles";
import SearchByListValues from "../SearchByListValues";
import { NAME_LIST_VALIDATION_SCHEMA } from "../../../../utils/validation";
import usePagination from "../../../../hooks/usePagination";
import { COMPLIANCE_API_URLS, ComplianceAPI } from "../../../../api/compliance";
import { appendCommaSeparatedValuesToQuery, TitleUpdated } from "../../utils";
import { ComplianceSearchActions } from "../../../../reducers/ComplianceSearchReducer/actions";
import Table from "../Table";
import { COLUMNS } from "./constants";

const HeliosFailedSearch: React.FC = () => {
  const classes = useStyles();
  const pagination = usePagination();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const handleChangePage = (page: number) => pagination.setPage(page);

  const {
    loading,
    data: { updated, results },
  } = useSelector((state) => state.complianceSearch.watchlistFailed);

  useQuery({
    enabled: !!search,
    queryKey: [COMPLIANCE_API_URLS.getFailed, search, pagination.page, pagination.limit],
    async queryFn({ signal }) {
      const query = new URLSearchParams();
      query.append("limit", pagination.limit.toString());
      query.append("page", pagination.page.toString());
      appendCommaSeparatedValuesToQuery(search, query, "names");

      dispatch(ComplianceSearchActions.fetchWatchlistFailedRequest());
      const response = await ComplianceAPI.getWatchlistFailedRequest(query.toString(), signal);
      pagination.setCount(response.data.results.count);
      dispatch(ComplianceSearchActions.fetchWatchlistFailedSuccess(response.data));
      return response;
    },
    onError(error) {
      console.error("Error fetching passes", error);
      dispatch(ComplianceSearchActions.fetchWatchlistFailedFailed());
    },
  });

  return (
    <div>
      <b className={classes.title}>Failed</b>

      <SearchByListValues
        fetchData={(values) => {
          setSearch(values.names);
        }}
        isLoading={false}
        name="names"
        label="Name"
        placeholder="Name Surname, Name Surname, e.t.c ..."
        initialValues={{ names: "" }}
        validationSchema={NAME_LIST_VALIDATION_SCHEMA}
      />

      <Table
        columns={COLUMNS}
        data={results.rows}
        loading={loading}
        pagination={pagination}
        handleChangePage={handleChangePage}
        extractKey={(row) => row.id}
        title={<TitleUpdated value={updated?.failure_date} />}
      />
    </div>
  );
};

export default HeliosFailedSearch;
