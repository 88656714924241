import React from "react";
import LinkIcon from "@material-ui/icons/Link";

import useStyles from "./useStyles";
import { IEntriesData } from "../../api/compliance/index.types";
import { CopyToClipboard } from "../../components/ui";
import { getTimeByTimeZone } from "../../utils/moment";

export const getQueryStringByStringValues = (by: string, values: string): string => {
  const arrayValues = values.trim().split(/[\s,]+/);
  const query = new URLSearchParams();
  arrayValues.forEach((val) => {
    query.append(by, val);
  });

  return query.toString();
};

export const appendCommaSeparatedValuesToQuery = (
  value: string,
  query: URLSearchParams,
  queryKey: string
): void => {
  value
    .trim()
    .replaceAll(", ", ",")
    .split(",")
    .forEach((val) => {
      query.append(queryKey, val.trim());
    });
};

export const getSelectedRowsData = (
  selectedRows: Record<"id", number>[],
  data: IEntriesData[]
): IEntriesData[] => {
  return data.filter((r) => selectedRows.find((selected) => r.id === selected.id));
};

const CellValue: React.FC<{ value: string }> = ({ value }) => {
  const classes = useStyles();
  return (
    <div className={classes.cellIconsContainer}>
      <a rel="noopener noreferrer" target="_blank" href={value}>
        <LinkIcon />
      </a>
      <CopyToClipboard value={value} useIcon />
    </div>
  );
};

interface ICellValueProps {
  value: string;
}
export const RenderCellValue: React.FC<ICellValueProps> = ({ value }) => {
  if (!value) return null;

  if (value.includes(",")) {
    const values = value.split(",");
    return (
      <>
        {values.map((v) => (
          <CellValue value={v} key={v} />
        ))}
      </>
    );
  }

  return <CellValue value={value} />;
};

export const replaceCommaAndNewLineSymbols = (value: string): string => {
  if (value) {
    return value.replaceAll(",", " ; ").replaceAll("\n", " ");
  }
  return "";
};
export const replaceNewLineAndTrim = (value: string): string => {
  if (value) {
    return value.replaceAll("\n", " ").trim();
  }
  return "";
};

interface ITitleUpdated {
  value: string | undefined | null;
}
export const TitleUpdated: React.FC<ITitleUpdated> = ({ value }) => {
  if (value) {
    return (
      <>
        Updated at: <b>{getTimeByTimeZone(value)}</b>
      </>
    );
  }
  return null;
};
