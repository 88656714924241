import { makeStyles } from "@material-ui/core/";

const useStyles = makeStyles({
  mainContainer: {
    minHeight: "calc(100vh - 110px)",
  },
  relatedTablesContainer: {
    display: "grid",
    gridTemplateColumns: "35% 15% 50%",
    columnGap: "5px",
  },
  heliosRelatedSearch: {
    display: "grid",
    gridTemplateColumns: "49% 49%",
    columnGap: "2%",
  },
  hr: {
    margin: "5px 0",
  },
  relativeTable: {
    height: "50vh",
    overflow: "auto",
  },
  container: {
    marginTop: 15,
  },
  tableContainer: {
    marginTop: 15,
  },
  title: {
    marginBottom: 5,
    display: "block",
  },
  tdEmail: {
    display: "flex",
    alignItems: "center",
  },
  borderBottom: {
    "& td": {
      borderBottom: "1px solid #000",
    },
  },
  cellIconsContainer: {
    display: "flex",
    alignItems: "center",
  },
});

export default useStyles;
